export default class FrontCommon {
  static preparedDefaultQuestions() {
    return [
      { text: "名前", value: "name" },
      { text: "住所", value: "address" },
      { text: "メールアドレス", value: "mailAddress" },
      { text: "会社名", value: "companyName" },
      { text: "生年月日", value: "birthdate" },
      { text: "電話番号", value: "tel" },
      { text: "社員番号", value: "employeeNumber" },
    ];
  }

  static defaultQuestions() {
    return [
      { text: "名前", value: "name" },
      { text: "住所", value: "address" },
      { text: "会社名", value: "companyName" },
      { text: "生年月日", value: "birthdate" },
      { text: "性別", value: "gender" },
      { text: "社員番号", value: "employeeNumber" },
    ];
  }
}
