<template>
  <div>
    <v-list class="mt-2">
      <v-subheader>Front</v-subheader>
      <v-divider></v-divider>
      <v-list-item-group v-model="route" color="primary" class="mt-2">
        <v-list-item @click="moveTo('readExam')" value="">
          <v-list-item-content>
            <v-list-item-title class="ml-3">受付</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="moveTo('print')" value="">
          <v-list-item-content>
            <v-list-item-title class="ml-3">印刷</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group no-action value="true">
          <template v-slot:activator>
            <v-list-item-title class="ml-3">設定</v-list-item-title>
          </template>

          <v-list-item
            v-for="(item, i) in pages"
            :key="i"
            @click="moveTo(item.pathName)"
            :value="item.pathName"
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
    </v-list>
  </div>

  <!-- <div>
    <v-list class="mt-2">
      <v-subheader>Front</v-subheader>
      <v-divider></v-divider>
      <v-list-item-group v-model="route" color="primary">
        <v-list-item
          v-for="(item, i) in pages"
          :key="i"
          @click="moveTo(item.pathName)"
          :value="item.pathName"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div> -->
</template>
<script>
export default {
  components: {},
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      route: "",
      pages: [
        {
          icon: "mdi-format-list-bulleted",
          text: "健診一覧",
          pathName: "allExam",
        },
        {
          icon: "mdi-pencil-plus",
          text: "新規健診作成",
          pathName: "makeExam",
        },
        {
          icon: "mdi-clipboard-text-outline",
          text: "デフォルト予診票",
          pathName: "allPrediction",
        },
      ],
    };
  },
  created() {
    this.route = this.$router.currentRoute.name;
  },
  methods: {
    moveTo(name) {
      if (this.$router.currentRoute.name !== name) {
        this.$router.push({ name });
      }
    },
  },
  watch: {
    $route(to) {
      this.route = to.name;
    },
  },
};
</script>
