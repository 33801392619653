import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/makeExam",
    name: "makeExam",
    component: () => import("../views/makeExam.vue"),
  },
  {
    path: "/allExam",
    name: "allExam",
    component: () => import("../views/allExam.vue"),
  },
  {
    path: "/readExam",
    name: "readExam",
    component: () => import("../views/readExam.vue"),
  },
  {
    path: "/print",
    name: "print",
    component: () => import("../views/print.vue"),
  },
  {
    path: "/allPrediction",
    name: "allPrediction",
    component: () => import("../views/allPrediction.vue"),
  },
  {
    path: "/makePrediction",
    name: "makePrediction",
    component: () => import("../views/makePrediction.vue"),
  },
  {
    path: "/makePredictionJson",
    name: "makePredictionJson",
    component: () => import("../views/makePredictionJson.vue"),
  },
  {
    path: "/confirmPrediction",
    name: "confirmPrediction",
    component: () => import("../views/confirmPrediction.vue"),
  },
  {
    path: "/completePrediction",
    name: "completePrediction",
    component: () => import("../views/completePrediction.vue"),
  },
  {
    path: "*",
    redirect: "/readExam",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
