import Vue from "vue";
import Vuex from "vuex";
import FrontCommon from "@/assets/js/frontCommon";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    predictionData: {
      title: "",
      questions: [],
      defaultQuestions: [],
    },
  },
  mutations: {
    predictionData(state, predictionData) {
      state.predictionData.title = predictionData.title
        ? predictionData.title
        : "";
      state.predictionData.questions = predictionData.questions
        ? predictionData.questions
        : [];
    },
    getDefaultQuestions(state) {
      state.defaultQuestions = FrontCommon.defaultQuestions();
    },
  },
  getters: {
    predictionData(state) {
      return state.predictionData;
    },
    defaultQuestionsValue(state) {
      const values = [];
      state.defaultQuestions.forEach((q) => {
        values.push(q.value);
      });
      return values;
    },
    defaultQuestionsText: (state) => (value) =>
      state.defaultQuestions.find((el) => el.value === value),
  },
  actions: {},
  modules: {},
});
